@import 'variables.scss';
@import url('https://use.fontawesome.com/releases/v5.13.0/css/all.css');

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Poppins/Poppins-Regular.ttf');
  font-display: swap;
}

* {
  font-family: 'Poppins', sans-serif;
  scroll-margin-top: 90px;
}

.grecaptcha-badge {
  display: none;
  visibility: hidden;
}
.adyen-checkout__card__brands__brand-wrapper {
  height: auto !important;
}
.CardInput-module_adyen-checkout__input__11tlB {
  border-color: $sizzlyPrimary !important;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.accordion-button:focus {
  outline: none;
  box-shadow: none;
}

.form-control {
  border-radius: $radiusSmall !important;
  border: 2px solid $systemGreyMedium !important;
  color: black !important;
  background: $systemGreyUltraLight;
  &:focus {
    border: 2px solid $sizzlyPrimary !important;
    box-shadow: none !important;
    background: $sizzlyPrimaryOpacity;
    + label {
      color: $sizzlyPrimary !important;
      opacity: 1 !important;
    }
  }
  + label {
    color: $systemGreyDark !important;
  }
}

.form-select {
  border-radius: $radiusSmall !important;
  border: 2px solid $systemGreyMedium !important;
  background-color: $systemGreyUltraLight;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  + label {
    color: $systemGreyDark !important;
  }
  &:focus {
    border: 2px solid $sizzlyPrimary !important;
    box-shadow: none !important;
    background-color: $sizzlyPrimaryOpacity;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23462fee' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    + label {
      color: $sizzlyPrimary !important;
      opacity: 1 !important;
    }
  }
  + label {
    color: $systemGreyDark !important;
  }
}

.shoppingBag.accordion-button.collapsed::after {
  content: '\f303' !important;
  font-family: 'Font Awesome 5 Free';
  color: $sizzlyPrimary;
  background-image: none;
  height: 1.5em;
  font-weight: 900 !important;
}

.shoppingBag.preDeletion.accordion-button.collapsed {
  border: 2px solid $systemRed !important;
}

.accordion-button:not(.collapsed),
.accordion-button:not(.collapsed)::after {
  background-color: $sizzlyPrimaryLight;
  color: $sizzlyPrimaryDark;
}

.offcanvas,
.offcanvas-header {
  border-right: #ffffff;
  text-align: center;
  color: white !important;
}

.nav-item {
  margin-right: 10px;
}

.navbar-light .navbar-nav {
  color: white;
  .nav-link,
  .nav-link.active,
  .show > .nav-link,
  &::after,
  &:hover {
    color: white;
  }
}

.dropdown-toggle {
  height: 20px;
  width: 37px;
  text-align: center;
  &:hover {
    color: white !important;
  }
  &::after {
    color: $sizzlyPrimary !important;
  }
}

.dropdown-menu {
  min-width: 5rem !important;
  border: none;
}

@media (max-width: 992px) {
  .navbar-expand-md .navbar-nav .dropdown-menu .show {
    background: $sizzlyPrimary;
    color: white;
    .dropdown-item {
      background: $sizzlyPrimary;
      color: white;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

@media (max-width: 992px) {
  .offcanvas {
    backdrop-filter: opacity(0.8) contrast(10);
    background: linear-gradient(-45deg, $sizzlyPrimary, $sizzlyPrimaryWineRed, $sizzlyPrimaryDark, $sizzlyPrimaryLight);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
  }
  .nav-item {
    margin-right: 0;
    margin-bottom: 5px;
    a,
    Button {
      background: none;
      border: none;
      color: white;
      margin: 0 auto;
      &:hover {
        text-decoration: underline;
        background: none;
        color: white;
      }
    }
  }
  .dropdown-menu {
    background: transparent;
    border-color: white;
    text-align: center;
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='200' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  mix-blend-mode: difference;
}

input:focus {
  outline: none;
  box-shadow: none;
}

.form-control:focus,
.form-control {
  color: $sizzlyPrimary;
}

html,
body {
  background: #ffffff;
  margin: 0;
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(85, 85, 85, 0.3);
    &:hover {
      background-color: rgba(85, 85, 85);
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@supports (-webkit-touch-callout: none) {
  button {
    color: black;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

svg tspan {
  font-family: 'FontAwesome', 'Poppins', sans-serif;
  font-size: 20px;
  background-color: blue;
}

.cookiesDisabled {
  padding: 20px;
  min-height: 40vh;
  text-align: center;
  margin-top: 10vh;
  font-size: 20px;
}

apple-pay-button {
  --apple-pay-button-width: 240px;
  --apple-pay-button-height: 40px;
  vertical-align: middle;
}

.pagination {
  justify-content: center;
  .page-item.active .page-link {
    color: white !important;
    background-color: $sizzlyPrimary !important;
    cursor: pointer;
  }
  .page-item.disabled span {
    color: $systemGreyLight !important;
  }
  .page-item {
    margin: 0 5px;
    --bs-pagination-border-width: 0px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: $radiusSmall;
    .page-link {
      font-size: 16px !important;
      height: 60px;
      width: 50px;
      color: $sizzlyPrimary !important;
      border-radius: $radiusSmall;
      padding: 5px 0 0 0;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        background-color: $sizzlyPrimaryMedium;
      }
      span {
        font-size: 20px !important;
      }
    }
    &:last-child,
    &:first-child {
      margin: 0 0;
      justify-content: center;
      align-items: center;
      span {
        font-size: 30px !important;
      }
    }
  }
}
